import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import { t } from '@/plugins/i18n'
import { destroyItem, getItems } from '@api/selection'
import { reactive, ref, watch } from '@vue/composition-api'

const cached = []

export default function useList() {
  const loading = ref(false)

  const tableList = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'Sort (asc)', value: 'sort' },
    { text: 'Item Name', value: 'name' },

    // { text: 'Added At', value: 'created_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableTotal = ref(0)
  const tableSelectedData = ref([])

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )

  // Filter
  const filters = reactive({
    selection_id: null,
  })

  const search = ref(null)

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce((a, key) => ({ ...a, [key]: filters[key] }), {})

  const loadItems = async () => {
    loading.value = true

    try {
      const params = {
        ...useTableOptions(options.value),
        ...parseFilters(),
        ...(search.value && { search: search.value }),
      }

      const {
        status,
        data: {
          data: { records, pagination },
        },
      } = await getItems(params)

      if (status === 200) {
        tableList.value = records
        tableTotal.value = pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const deleteItem = item =>
    destroyItem(item.id)
      .then(() => {
        useNotifySuccess({ content: 'Delete Success' })
        loadItems()
      })
      .catch(useNotifyErrors)

  const cachable = [options]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    tableSelectedData.value = []

    loadItems()
    cache()
  })

  const getItemName = item => {
    const mapping = {
      tag: 'name',
      article: 'title',
    }

    let results = 'Item cannot be mapped'

    Object.entries(mapping).forEach(([key, value]) => {
      if (item.selectable_type.toLowerCase().includes(key)) {
        console.log('item is', item)
        results = t(item.selectable[value])
      }
    })

    return results
  }

  return {
    options,
    filters,
    search,

    tableList,
    tableColumns,
    tableTotal,
    tableSelectedData,

    getItemName,

    loading,
    loadItems,
    deleteItem,
  }
}
