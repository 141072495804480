import { useNotifyErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import store from '@/store'
import { destroySelection, fetchSelection } from '@api/selection'
import { ref } from '@vue/composition-api'

export default function useSelectionView() {
  const selectionData = ref(null)
  const loading = ref(false)

  // fetch data
  const getSelection = id => {
    selectionData.value = null
    loading.value = true

    return fetchSelection(id)
      .then(res => {
        selectionData.value = res.data.data

        return res
      })
      .catch(useNotifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  const trashSelection = id => destroySelection(id).catch(useNotifyErrors)

  const confirmTrashSelection = selection =>
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [
        {
          title: 'Confirm Action',
          content: `Confirm Trash selection: <b>${t(selection.title)}</b> ?`,
          resolve: () => trashSelection(selection.id).then(resolve),
          resolveText: 'Confirm',
          reject: () => reject(),
          rejectText: 'No',
        },
      ])
    })

  return {
    loading,

    selectionData,

    getSelection,
    trashSelection,
    confirmTrashSelection,
  }
}
