var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-view"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v("Back")])])], 1), _vm.selectionData ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.t(_vm.selectionData.name)))]), _c('v-card-text', [_c('p', [_vm._v(" Key: "), _c('b', [_vm._v(_vm._s(_vm.selectionData.key))])]), _c('p', [_vm._v(" Type: "), _c('b', [_vm._v(_vm._s(_vm.selectionData.type))])]), _c('p', [_vm._v(" " + _vm._s(_vm.selectionData.notes) + " ")])])], 1), _c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-card-title', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Items")]), _c('a', {
    on: {
      "click": function click($event) {
        _vm.adding = true;
      }
    }
  }, [_vm._v("Add +")])]), _vm.selectionData ? _c('v-card-text', [_c('SelectionItemsTable', {
    attrs: {
      "selection": _vm.selectionData
    }
  })], 1) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    staticClass: "mb-2"
  }, [_c('v-card-title', [_vm._v("Information")]), _vm.selectionData ? _c('v-card-text', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('span', [_vm._v("Created At")]), _c('span', [_vm._v(_vm._s(_vm._f("dateTime")(_vm.selectionData.created_at)))])])]) : _vm._e()], 1), _c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-card-title', [_vm._v("Actions")]), _vm.selectionData ? _c('v-card-text', {
    staticClass: "d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.aside = true;
      }
    }
  }, [_vm._v(" Edit ")])], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.aside ? _c('selection-aside', {
    attrs: {
      "resource": _vm.selectionData
    },
    on: {
      "changed": function changed($event) {
        _vm.reloadSelection();
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e(), _vm.adding ? _c('SelectionItemAside', {
    attrs: {
      "selection": _vm.selectionData
    },
    on: {
      "changed": function changed($event) {
        _vm.reloadSelection();
        _vm.adding = false;
      }
    },
    model: {
      value: _vm.adding,
      callback: function callback($$v) {
        _vm.adding = $$v;
      },
      expression: "adding"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }