<template>
  <v-data-table
    v-model="tableSelectedData"
    class="table-section text-no-wrap"
    :headers="tableColumns"
    :items="tableList"
    :options.sync="options"
    :server-items-length="tableTotal"
    :loading="loading"
  >
    <template #[`item.name`]="{ item }">
      {{ getItemName(item) }}
    </template>

    <!-- actions -->
    <template #[`item.actions`]="{ item }">
      <div class="d-flex align-center justify-center">
        <!-- dropdown -->
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="18">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
              @click="deleteItem(item).then(loadItems)"
            >
              <v-list-item-title>
                <v-icon
                  size="18"
                  class="me-2"
                >
                </v-icon>
                <span>Remove</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {
mdiDotsVertical,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useItemList from '../item-list/useItemList'

export default {
  props: {
    selection: {
      type: [Object, Boolean],
      default: () => false,
    },
  },

  setup(props) {
    const {
      tableList,
      tableColumns,
      tableTotal,
      tableSelectedData,
      options,
      filters,

      getItemName,
      loadItems,
      deleteItem,
    } = useItemList()

    const loading = ref(false)

    filters.selection_id = props.selection?.id

    loadItems()

    return {
      tableList,
      tableColumns,
      tableTotal,
      tableSelectedData,
      options,

      getItemName,
      loading,
      deleteItem,

      filters,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
