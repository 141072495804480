<template>
  <v-navigation-drawer
    :value="active"
    permanent
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 580 : '100%'"
    app

    @input="(val) => $emit('aside', val)"
  >
    <v-container>
      <a @click="$emit('aside', false)">Back</a>
    </v-container>

    <selection-item-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      :options="options"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import useArticleList from '@/modules/blog/views/article-list/useArticleList'
import useArticleTagList from '@/modules/blog/views/article-tag-list/useArticleTagList'
import { t } from '@/plugins/i18n'
import { AddSelectionItem } from '@api/selection'
import { mdiClose } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import SelectionItemForm from './SelectionItemForm.vue'

export default {
  components: { SelectionItemForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    selection: {
      type: Object,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])
    const options = ref([])

    const { loadArticleTags } = useArticleTagList()
    const { loadArticles } = useArticleList()

    onMounted(() => {
      switch (props.selection.type) {
        case 'article':
          loadArticles().then(res => {
            options.value = res.data.data.records.map(d => ({
              title: t(d.title),
              value: d.id,
            }))
          })
          break

        case 'article_tag':
          loadArticleTags().then(res => {
            options.value = res.data.data.records.map(d => ({
              title: t(d.name),
              value: d.id,
            }))
          })
          break

        default:
          break
      }
    })

    const handleSubmit = async form => {
      loading.value = true
      const addType = props.selection.type.replace('_', '-') // article_tag -> article-tag
      const request = AddSelectionItem(addType, {
        ...form,
        selection_id: props.selection.id,
      })

      await request
        .then(async res => {
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,
      options,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
