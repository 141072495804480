<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>
      </v-col>
    </v-row>

    <v-row v-if="selectionData">
      <v-col
        cols="8"
      >
        <v-card>
          <v-card-title>{{ t(selectionData.name) }}</v-card-title>

          <v-card-text>
            <p>
              Key:
              <b>{{ selectionData.key }}</b>
            </p>
            <p>
              Type:
              <b>{{ selectionData.type }}</b>
            </p>
            <p>
              {{ selectionData.notes }}
            </p>
          </v-card-text>
        </v-card>

        <v-card class="mt-2">
          <v-card-title class="d-flex justify-space-between">
            <span>Items</span>
            <a @click="adding = true">Add +</a>
          </v-card-title>

          <v-card-text v-if="selectionData">
            <SelectionItemsTable :selection="selectionData" />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="4"
      >
        <v-card class="mb-2">
          <v-card-title>Information</v-card-title>

          <v-card-text v-if="selectionData">
            <div class="d-flex justify-space-between">
              <span>Created At</span>
              <span>{{ selectionData.created_at | dateTime }}</span>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-2">
          <v-card-title>Actions</v-card-title>

          <v-card-text
            v-if="selectionData"
            class="d-flex justify-center"
          >
            <v-btn
              color="info"
              class="mr-2"
              @click="aside = true"
            >
              Edit
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <selection-aside
      v-if="aside"
      v-model="aside"
      :resource="selectionData"
      @changed="
        reloadSelection()
        aside = false
      "
    />

    <SelectionItemAside
      v-if="adding"
      v-model="adding"
      :selection="selectionData"
      @changed="
        reloadSelection()
        adding = false
      "
    />
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import router from '@/router'
import { dateTime } from '@core/utils/filter'
import { onMounted, ref } from '@vue/composition-api'
import SelectionAside from '../selection-resource/SelectionAside.vue'
import SelectionItemAside from '../selection-resource/SelectionItemAside.vue'
import SelectionItemsTable from './SelectionItemsTable.vue'
import useSelectionView from './useSelection'

export default {
  components: {
    SelectionItemAside,
    SelectionAside,
    SelectionItemsTable,
  },

  filters: {
    dateTime,
  },

  setup() {
    const {
      getSelection, selectionData,
    } = useSelectionView()

    const aside = ref(false)

    const reloadSelection = async () => {
      selectionData.value = null

      await getSelection(router.currentRoute.params.id)
    }

    onMounted(() => reloadSelection())

    const adding = ref(false)

    return {
      aside,
      adding,

      selectionData,
      reloadSelection,
      t,

    }
  },
}
</script>

<style lang="scss">
.content {
  img {
    max-width: 100%;
  }
}
</style>
